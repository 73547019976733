(function () {
    angular.module('informaApp')
        .service('SummaryViewMapper', SummaryViewMapper);

    function SummaryViewMapper() {
        return {
            mapAll(source) {
                return source.map(x => this.map(x));
            },

            map(data) {
                return {
                    text: data.name,
                    rows: data.rows,
                    isPrivate: data.isPrivate
                }
            }
        };
    }
})();